import React from "react";
import iconCallOut from "../assets/img/svg/Call_Out.svg";
import iconEmail from "../assets/img/svg/Email_Us.svg";
import "../styles/CustomStyle.css";


function ContactUs() {
  return (
    <div>
      <section className="slice slice-lg">
        <div className="container">
            <div className="row mb-5 justify-content-center text-center">
                <div className="col-lg-8 col-md-10">
                    <h2 className=" mt-4">Need personal assistance?</h2>
                    <div className="mt-2">
                        <p className="lead lh-180">Want to share any feedback with us, report a technical issue or just ask us a question? Feel free to contact us and we will get back to you shortly.</p>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-5">
                    <div className="card">
                        <div className="card-body p-5 text-center">
                            <div className="pb-4">
                                <img src={iconCallOut} alt="Phone illustration" className="svg-inject img-fluid" style={{height: 40}} />
                            </div>
                            <h5>+1 (646) 600-9191</h5>
                            <p className="mt-2 mb-0">
                                Our support team is here for you.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="card">
                        <div className="card-body p-5 text-center">
                            <div className="pb-4">
                                <img src={iconEmail} alt="Email illustration" className="svg-inject img-fluid" style={{height: 40}} />
                            </div>
                            <h5><a href="mailto:support@aivideostube.com">support@aivideostube.com</a></h5>
                            <p className="mt-2 mb-0">
                                You'll get a reply in 3 to 12 hours.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
}

export default ContactUs;
