// @ts-nocheck
import React from "react";
import Wrapper from "../styles/Sidebar";
import { NavLink } from "react-router-dom";
import {
  HistoryIcon,
  HomeIcon,
  LibIcon,
  LikeIcon,
  SubIcon,
  TrendingIcon,
  VidIcon,
} from "./Icons";
import SidebarAuth from "./SidebarAuth";
import { useAuth } from "../context/auth-context";
import Subscriptions from "./Subscriptions";

function Sidebar({ isSidebarOpen }) {
  const user = useAuth();

  return (
    <Wrapper open={isSidebarOpen}>
      <NavLink exact to="/" activeClassName="active">
        <div className="icon">
          <HomeIcon />
          <span>Home</span>
        </div>
      </NavLink>

      <NavLink to="/feed/trending" activeClassName="active">
        <div className="icon">
          <TrendingIcon />
          <span>Trending</span>
        </div>
      </NavLink>

      <NavLink to="/feed/subscriptions" activeClassName="active">
        <div className="icon">
          <SubIcon />
          <span>Creators you follow</span>
        </div>
      </NavLink>

      <div className="divider"></div>

      <NavLink to="/feed/library" activeClassName="active">
        <div className="icon">
          <LibIcon />
          <span>Library</span>
        </div>
      </NavLink>

      <NavLink to="/feed/history" activeClassName="active">
        <div className="icon">
          <HistoryIcon />
          <span>History</span>
        </div>
      </NavLink>

      <NavLink to="/feed/my_videos" activeClassName="active">
        <div className="icon">
          <VidIcon />
          <span>Your videos</span>
        </div>
      </NavLink>

      <NavLink to="/feed/liked_videos" activeClassName="active">
        <div className="icon">
          <LikeIcon />
          <span>Liked videos</span>
        </div>
      </NavLink>

      <div className="divider"></div>

      {user ? <Subscriptions user={user} /> : <SidebarAuth />}

      <div className="divider"></div>

      <NavLink to="/feed/how_works" activeClassName="">
        <div className="icon">
          <span>How it Works</span>
        </div>
      </NavLink>
      <NavLink to="/feed/about" activeClassName="">
        <div className="icon">
          <span>About</span>
        </div>
      </NavLink>
      <NavLink to="/feed/contact" activeClassName="">
        <div className="icon">
          <span>Contact</span>
        </div>
      </NavLink>
      

      <div className="divider"></div>

      <div className="icon terms">

      <NavLink to="/feed/privacy_policy" activeClassName="">
          <span>Privacy</span>
      </NavLink>
      <span>.</span>
      <NavLink to="/feed/terms_service" activeClassName="">
          <span>Terms</span>
      </NavLink>
      </div>

      {/* <NavLink to="/feed/privacy_policy" activeClassName="">
        <div className="icon">
          <span>Privacy Policy</span>
        </div>
      </NavLink> */}
      {/* <NavLink to="/feed/terms_service" activeClassName="">
        <div className="icon">
          <span>Terms of Service</span>
        </div>
      </NavLink> */}

      {/* <NavLink to="/feed/copyrights" activeClassName="">
        <div className="">
          <span>Copyrights</span>
        </div>
      </NavLink> */}
    </Wrapper>
  );
}

export default Sidebar;
