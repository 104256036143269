import React from "react";
import illustrationOne from "../assets/img/svg/illustrations/illustration-1.svg";
import illustrationTwo from "../assets/img/svg/illustrations/illustration-2.svg";
import illustrationFive from "../assets/img/svg/illustrations/illustration-5.svg";
import illustrationSix from "../assets/img/svg/illustrations/illustration-6.svg";
import illustrationSeven from "../assets/img/svg/illustrations/illustration-7.svg";
import "../styles/CustomStyle.css";

function HowItWorks() {
  return (
    <div>
      <section className="slice py-5">
        <div className="container">
            <div className="row row-grid align-items-center">
                <div className="col-12 col-md-5 col-lg-6 order-md-2">
                    <figure className="w-100">
                      <img src={illustrationOne} alt="How it work page illustration" className="img-fluid mw-md-120" />
                    </figure>
                </div>
                <div className="col-12 col-md-7 col-lg-6 order-md-1 pr-md-5">
                    <h1 className="display-4 text-center text-md-left mb-3">
                        It's time to up your <strong className="text-primary">content creation game</strong>
                    </h1>
                    <p className="lead text-center text-md-left text-muted">
                        Publishing your AI-generated videos on AI-Tube is incredibly easy. It only takes a short video description or text-to-video prompt used to generate your video, and you are one upload away to share your creative mind with the world and gain more followers.
                    </p>
                </div>
            </div>
        </div>
    </section>
    <section className="slice slice-lg pt-lg-6 pb-0 pb-lg-6">
        <div className="container">
            <div className="row justify-content-lg-end">
                <div className="col-lg-5 text-lg-right">
                    <p className="lh-180 mb-3">
                        Our mission is to share your creative work with the world and connect you with your followers.
                    </p>
                </div>
                <div className="col-lg-6">
                    <h3 className="display-4 lh-130"> <strong className="text-primary">3 Steps</strong> to publish your AI-generated video and grow your channel</h3>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-4">
                    <div className="card border-0 bg-soft-danger">
                        <div className="card-body pb-5">
                            <div className="pt-4 pb-5">
                              <img src={illustrationFive} alt="How it work page illustration" className="img-fluid img-center" style={{height: 200}} />
                            </div>
                            <h5 className="h4 lh-130 text-dark mb-3">Sign-in with your Google account</h5>
                            <p className="text-dark opacity-6 mb-0">Easy sign-in with your Google account for a seamless experience.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card border-0 bg-soft-success mt-lg-5">
                        <div className="card-body pb-5">
                            <div className="pt-4 pb-5">
                              <img src={illustrationSix} alt="How it work page illustration" className="img-fluid img-center" style={{height: 200}} />
                            </div>
                            <h5 className="h4 lh-130 text-dark mb-3">Update your profile and channel name</h5>
                            <p className="text-dark opacity-6 mb-0">Change your default profile picture and username to your channel picture and name.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card border-0 bg-soft-warning mt-lg-7">
                        <div className="card-body pb-5">
                            <div className="pt-4 pb-5">
                              <img src={illustrationSeven} alt="How it work page illustration" className="img-fluid img-center" style={{height: 200}} />
                            </div>
                            <h5 className="h4 lh-130 text-dark mb-3">Upload your AI-generated video</h5>
                            <p className="text-dark opacity-6 mb-0">Upload your AI-generated video and fill up the title and description or text-to-video prompt of your video.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="slice slice-lg">
        <div className="container">
            <div className="row row-grid justify-content-around align-items-center">
                <div className="col-lg-6 order-lg-2 ml-lg-auto pl-lg-6">
                    <h5 className="h2 mt-4">For consumers</h5>
                    <p className="lead lh-190 my-4">
                        AI-Tube is the best place for people to discover AI-Content creators from around the world and learn about their creative work.
                    </p>
                    <ul className="list-unstyled">
                        <li className="py-2">
                            <div className="d-flex align-items-center">
                                <div>
                                    <div className="icon icon-shape icon-primary icon-sm rounded-circle mr-3">
                                        <i className="fas fa-store-alt"></i>
                                    </div>
                                </div>
                                <div>
                                    <span className="h6 mb-0">Perfect for people looking to learn more about AI-Content creators</span>
                                </div>
                            </div>
                        </li>
                        <li className="py-2">
                            <div className="d-flex align-items-center">
                                <div>
                                    <div className="icon icon-shape icon-warning icon-sm rounded-circle mr-3">
                                        <i className="fas fa-palette"></i>
                                    </div>
                                </div>
                                <div>
                                    <span className="h6 mb-0">AI-Tube is a video platform for all sort of AI-generated videos for educational and entertainment purpose</span>
                                </div>
                            </div>
                        </li>
                        <li className="py-2">
                            <div className="d-flex align-items-center">
                                <div>
                                    <div className="icon icon-shape icon-success icon-sm rounded-circle mr-3">
                                        <i className="fas fa-cog"></i>
                                    </div>
                                </div>
                                <div>
                                    <span className="h6 mb-0">AI-Tube is a search engine for AI-generated videos</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="col-lg-6 order-lg-1">
                    <div className="position-relative">
                      <img src={illustrationTwo} alt="How it work page illustration" className="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
}

export default HowItWorks;
