import React from "react";
import imgCover from "../assets/img/img-bg.jpg";
import imgOne from "../assets/img/img-1.jpg";
import imgTwo from "../assets/img/img-2.jpg";
import imgThree from "../assets/img/img-3.jpg";
import imgFour from "../assets/img/img-4.jpg";
import "../styles/CustomStyle.css";

function AboutUs() {
  return (
      <div>
          
    <section className="slice py-8 bg-dark bg-cover bg-size--cover" style={{backgroundImage: "url(" + imgCover + ")"}}>
        <span className="mask bg-gradient-dark opacity-9"></span>
        <div className="container d-flex align-items-center text-center text-lg-left pt-5 pb-5 pt-lg-6">
            <div className="col px-0">
                <div className="row row-grid align-items-center">
                    <div className="col-lg-8 text-center text-lg-left">
                        <h1 className="text-white mb-4">
                            AI-Tube is the first ever platform for AI-generated video made by content creators.
                        </h1>
                        <p className="lead text-white opacity-8">
                            This is the era of "AI-Content Creators". Text-to-video is a game-changing technology for content creators who want to express their creativity. The new breed of "AI-Content Creators" needs a platform to publish their creative work. At AI-Tube, we are deeply passionate about working with content creators and helping them share their work with the world and grow their online audience.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="slice slice-lg">
        <div className="container">
            <span className="badge badge-primary badge-pill">Our vision</span>
            <div className="row mt-4">
                <div className="col-lg-6 pr-lg-5">
                    <p className="h5 lh-180 mb-3">
                        Our vision is to become the platform by default for all AI-generated video content for educational or entertainment purpose. We want to create a unique and efficient way to connect AI-Content Creators with their followers.
                    </p>
                </div>
                <div className="col-lg-6">
                    <p className="lead lh-180">
                        Text-to-video technology is a great way to give perspective and life to our thoughts.
                    </p>
                    <p className="lead lh-180">
                        AI-Tube is helping AI-Content Creators share their work with the world.
                    </p>
                </div>
            </div>
        </div>
    </section>
    <section className="slice slice-lg">
        <div className="container">
            <div className="row">
                <div className="col-lg-5 col-6">
                    <img src={imgOne} alt="Company team at the event" className="rounded-lg img-fluid" />
                    <div className="text-right mt-4">
                        <img src={imgTwo} alt="Company team at the event" className="rounded-lg img-fluid w-50" />
                    </div>
                </div>
                <div className="col-lg-7 col-6">
                    <img src={imgThree} alt="Company team at the event" className="rounded-lg img-fluid w-75" />
                    <div className="text-right mt-4">
                        <img src={imgFour} alt="Company team at the event" className="rounded-lg img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </section>
      </div>
  );
}

export default AboutUs;
