import React from "react";
import Button from "../styles/Auth";
import { SignInIcon } from "./Icons";
import { GoogleLogin } from "@react-oauth/google";
import { authenticate } from "../utils/api-client";

function GoogleAuth() {
  const handleSuccess = (response) => {
    authenticate(response.credential);
  };

  const handleFailure = (error) => {
    console.log("Google login failed", error);
  };

  return (
    <GoogleLogin
      onSuccess={handleSuccess}
      onError={handleFailure}
      useOneTap
      render={(renderProps) => (
        <Button
          tabIndex={0}
          type="button"
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
        >
          <span className="outer">
            <span className="inner">
              <SignInIcon />
            </span>
            Sign In with Google
          </span>
        </Button>
      )}
    />
  );
}

export default GoogleAuth;
