import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AppProviders from "./components/AppProviders";
import { GoogleOAuthProvider } from '@react-oauth/google';


ReactDOM.render(
  <GoogleOAuthProvider clientId="1013935665343-30actngbbu1u64a1lfr17e4rm9023ih3.apps.googleusercontent.com">
    <AppProviders>
      <App />
    </AppProviders>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);
